/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "~swiper/swiper.min.css";
@import "~swiper/modules/navigation/navigation.min.css";
@import "~swiper/modules/pagination/pagination.min.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .color-primary {
    color: var(--ion-color-primary);
  }
}

/* LEAFLET */
@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

.leaflet-tooltip {
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 4px;
  font-size: 14px;
  color: var(--ion-color-dark);
  line-height: 17px;

  .custom-id {
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: sans-serif;
  }
}
.leaflet-div-icon {
  background: transparent !important;
  border: none !important;
}
.map-tiles-dark {
  filter: var(--map-tiles-dark-mode-filter, none);
}

.map-pin {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background: var(--ion-color-primary);
  display: inline-block;
  border-bottom-right-radius: 0;
  position: relative;
  transform: rotate(45deg);
  border: 1px solid var(--ion-color-primary);
}
.map-pin::before {
  content: "";
  background: white;
  width: 50%;
  height: 50%;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0.1rem 0.1rem 0.2rem 0.1rem rgba(0, 0, 0, 0.1);
}

.map-pin::after {
  content: "";
  background: rgba(128, 128, 128, 0.2);
  width: 75%;
  height: 75%;
  border-radius: 100%;
  position: absolute;
  top: 100%;
  left: 100%;
  transform: translate(-50%, -50%) rotate(45deg) scaleX(0.5);
}

ion-content {
  --background: var(--ion-color-light);
}

ion-action-sheet.custom-action-sheet {
  --background: var(--ion-color-light);
  --backdrop-opacity: 0.6;
  --button-background-selected: var(--ion-color-primary);
  --button-color: var(--ion-color-primary);
  --color: var(--ion-color-dark);
  --ion-color-danger: var(--ion-color-danger);
}

.confirm-snackbar {
  background: var(--ion-color-primary);
  border-radius: 20px;
  margin: 0 !important;
  position: absolute;
  bottom: 15px;

  span {
    color: var(--ion-color-dark);
    font-family: sans-serif;
  }
}

.seg-holder {
  padding: 10px 10px 16px 10px;
  border-radius: 0 0 16px 16px !important;
  ion-segment {
    border: 1px solid var(--ion-color-primary);
    width: 100%;

    ion-segment-button {
      --color: #676767;
      --color-checked: white;
      --indicator-color: var(--ion-color-primary);
      height: 2rem;
      width: 100%;
      margin: 0;

      ion-label {
        font-family: sans-serif;
        color: #676767;
      }
    }

    .segment-button-checked {
      ion-label {
        color: white !important;
      }
    }
  }
}

ion-modal {
  --height: 95%;
  --width: 92%;
  --border-radius: 5px;
  --box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
}

ion-modal::part(backdrop) {
  background: black;
  opacity: 1;
}

ion-modal ion-toolbar {
  --background: var(--ion-color-primary);
  --color: white;
}

.form-small-message {
  font-size: 12px;
  color: var(--ion-color-dark);
}

.alert-radio-label.sc-ion-alert-md {
  color: var(--ion-color-dark) !important;
  white-space: normal !important;
}

.alert-radio-group {
  border: none !important;
}

.icon-light {
  color: white !important;
}

button#button-login {
  background-color: var(--ion-color-primary) !important;
  padding: 10px;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  font-family: sans-serif;
  text-transform: uppercase;
  margin: 0 0 16px 0;
  width: 100%;
}

.version {
  font-size: 12px;
  padding: 2px 4px;
  color: var(--ion-color-dark);
  width: 200px;
  margin: 0 auto;
  opacity: 0.5;

  &.mobile {
    text-align: center;
    padding: 6px;
  }

  &.desktop {
    position: fixed;
    bottom: 0;
    right: 0;
    background: white;
    border-radius: 5px 0 0 0;
    opacity: 0.3;
    line-height: 10px;
    cursor: none;
  }

  &:hover {
    opacity: 1;
  }
}

.required {
  &::after {
    content: " *";
    color: var(--ion-color-danger);
  }
}

.footer {
  --background: var(--ion-color-light);
}

ion-toggle {
  height: 10px;
  width: 60px;

  --background: #ddd;
  --background-checked: #ddd;

  --handle-background: #eb7769;
  --handle-background-checked: var(--ion-color-primary);

  --handle-width: 25px;
  --handle-height: 27px;
  --handle-max-height: auto;
  --handle-spacing: 6px;

  --handle-border-radius: 4px;
  --handle-box-shadow: none;

  /* Required for iOS handle to overflow the height of the track */
  overflow: visible;
  contain: none;
}

ion-item {
  border-radius: 5px;
}
